<template>
	<div class="exchange_pages bg_white">
		<div class="bg_white ">
			<el-row class="p2 c_gray" type="flex" align="middle">
				<el-col :span=4 class="tc" style="font-size:30px;">
					<router-link to="/asset" class="c_gray">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</router-link>
				</el-col>
				<el-col :span=16 class="f16">
					{{$t('兑换')}}
				</el-col>
				<el-col :span="4">&nbsp;</el-col>
			</el-row>
		</div>



		<div class="p5" style="padding-top:70px;">
			<div style="text-align: left;" class="p1">
				{{$t('发送')}}
				<span style="color:#999;"> ( {{$t('可用余额')}}: <a style="color:#2774fe;text-decoration: underline;" href="javascript:void(0);" @click="max">{{balance}}</a> )</span>
			</div>
			<el-row type="flex" align="middle" justify="center" style="border: 1px solid #ccc;border-radius: 5px;">
				<el-col :span="16">
					<el-input v-model="from_amount" @input="inputKeyup" @focus="changeType(0)"></el-input>
				</el-col>

				<el-col :span="8">
					<el-select v-model="from_coin" placeholder="请选择" style="text-align: center;" @change="changeFrom">
						<div slot="prefix">
							<img :src="from_img" style="width: 24px;padding-top:8px;" alt="">
						</div>

						<el-option v-for="item in coins" :key="item.value" :label="item.label" :value="item.value">
							<el-row type="flex" align="middle">
								<el-col :span="12">
									<img :src="item.img" alt="" style="width:24px;display: block;">
								</el-col>
								<el-col :span="12">{{item.value}}</el-col>
							</el-row>
						</el-option>
					</el-select>
				</el-col>
			</el-row>

			<div class="p3 mt3">
				<el-row type="flex" align="middle">
					<el-col :span="18">
						<span></span>
					</el-col>
					<el-col :span="6">
						<i class="el-icon-sort f16 p2" style="border:1px solid #aaa;border-radius: 5px;color:#aaa;" @click="switchOption"></i>
					</el-col>
				</el-row>
			</div>

			<div style="text-align: left;" class="p1">{{$t('获得')}}</div>
			<el-row type="flex" align="middle" justify="center" style="border: 1px solid #ccc;border-radius: 5px;">
				<el-col :span="16">
					<el-input v-model="to_amount" @input="inputKeyup" @focus="changeType(1)"></el-input>
				</el-col>

				<el-col :span="8">
					<el-select v-model="to_coin" placeholder="请选择" style="text-align: center;" @change="changeTo">
						<div slot="prefix">
							<img :src="to_img" style="width: 24px;padding-top:8px;" alt="">
						</div>

						<el-option v-for="item in coins" :key="item.value" :label="item.label" :value="item.value">
							<el-row type="flex" align="middle">
								<el-col :span="12">
									<img :src="item.img" alt="" style="width:24px;display: block;">
								</el-col>
								<el-col :span="12">{{item.value}}</el-col>
							</el-row>
						</el-option>
					</el-select>
				</el-col>
			</el-row>


			<div class="p3">
				<el-button type="primary" class="mt4 w100" :disabled="from_amount>0?false:true" round @click="submit">{{$t('兑换')}}</el-button>
			</div>
			<div class="pt4 c_gray tl">
				{{$t('请注意，兑换金额将按照提交时的实际汇率进行结算。')}}
			</div>
		</div>

		<el-dialog title="" :visible.sync="show_result_dialog" :close-on-click-modal="false" width="70%" center>
			<div class="tc">
					<i class="el-icon-circle-check" style="font-size: 30px;display: block;color: #2abb46;background-color: #2abb46;width: 30px;height: 30px;margin: 0 auto;color: #fff;border-radius: 15px;"></i>
					<div class="mt2">{{$t('兑换成功')}}</div>
			</div>
		</el-dialog>
	</div>	
</template>

<script>
	export default {
		name: "ExchangeView",
		data() {
			return {
				type: 0, // 0)固定from，1)固定to
				balance_list: {
					'USDT': 0.00,
					'BTC': 0.00,
					'ETH': 0.00,
					'XRP': 0.00,
					'BCH': 0.00,
					'LTC': 0.00,
					'DOT': 0.00,
					'FIL': 0.00,
					'DOGE': 0.00,
					'SOL': 0.00,
					'TRX': 0.00,
					'AVAX': 0.00,
					'SAND': 0.00,
					'UNI': 0.00,
					'ADA': 0.00,
					'SHIB': 0.00,
					'LINK': 0.00,
					'IOTA': 0.00,
				},
				show_result_dialog: false,
				coins: [{
					value: 'USDT',
					img: require('../assets/usdt.png'),
				}, {
					value: 'BTC',
					img: require('../assets/btc.png'),
				}, {
					value: 'ETH',
					img: require('../assets/eth.png'),
				}, {
					value: 'XRP',
					img: require('../assets/xrp.png'),
				}, {
					value: 'BCH',
					img: require('../assets/bch.png'),
				}, {
					value: 'LTC',
					img: require('../assets/ltc.png'),
				}, {
					value: 'DOT',
					img: require('../assets/dot.png'),
				}, {
					value: 'FIL',
					img: require('../assets/fil.png'),
				}, {
					value: 'DOGE',
					img: require('../assets/doge.png'),
				}, {
					value: 'SOL',
					img: require('../assets/sol.png'),
				}, {
					value: 'TRX',
					img: require('../assets/trx.png'),
				}, {
					value: 'AVAX',
					img: require('../assets/avax.png'),
				}, {
					value: 'SAND',
					img: require('../assets/sand.png'),
				}, {
					value: 'UNI',
					img: require('../assets/uni.png'),
				}, {
					value: 'ADA',
					img: require('../assets/ada.png'),
				}, {
					value: 'SHIB',
					img: require('../assets/shib.png'),
				}, {
					value: 'LINK',
					img: require('../assets/link.png'),
				}, {
					value: 'IOTA',
					img: require('../assets/iota.png'),
				}],
				from_coin: '',
				from_img: '',
				from_amount: 0,

				to_coin: '',
				to_img: '',
				to_amount: 0,

				flag_update: false,
				update_delay: 500,
			}
		},
		computed:{
			balance: function(){
				return this.balance_list[this.from_coin];
			}
		},
		methods: {
			changeType: function(t){
				this.type = t;
			},
			max: function(){
				this.type = 0; // fix from
				this.from_amount = this.balance;
				this.inputKeyup();
			},
			inputKeyup: function(){
				this.flag_update = true;
				let that = this;
				setTimeout(function(){
					that.getExchangeResult();
				}, that.update_delay);
			},
			changeFrom: function(o){
				this.type = 0;
				for( let i in this.coins){
					if(this.coins[i].value == o){
						this.from_img = this.coins[i].img;
						break;
					}
				}
				this.flag_update = true;
				let that = this;
				setTimeout(function(){
					that.getExchangeResult();
				}, that.update_delay);
			},
			changeTo: function(o){
				this.type = 1;
				for( let i in this.coins){
					if(this.coins[i].value == o){
						this.to_img = this.coins[i].img;
						break;
					}
				}
				this.flag_update = true;
				let that = this;
				setTimeout(function(){
					that.getExchangeResult();
				}, that.update_delay);
			},
			switchOption: function(){
				let tmp_f_c = this.from_coin;
				let tmp_f_i = this.from_img;
				this.from_coin = this.to_coin;
				this.from_img = this.to_img;
				this.to_coin = tmp_f_c;
				this.to_img = tmp_f_i;

				// request the receive result
				this.flag_update = true;
				this.getExchangeResult();
			},
			getExchangeResult: function(){
				if( this.flag_update === false ){
					return
				}
				this.flag_update = false;
				let that = this;
				if( that.type == 0 && that.from_amount <= 0 ){
					return false;
				} else if( that.type == 1 && that.to_amount <= 0 ){
					return false;
				}
				that.greq('post', '/api/exchange_result2', { 
					from: that.from_coin, 
					amount: that.type ? that.to_amount : that.from_amount, 
					to: that.to_coin, 
					do: 0, 
					type: that.type 
				}, function (data) {
					if(data.status){
						if( that.type == 0 ){
							that.to_amount = data.data.amount;
						} else {
							that.from_amount = data.data.amount;
						}
						
					} else {
						that.$message({message:data.msg,type:'error'})
					}
				})
			},
			submit:function(){
				let that = this;
				this.$parent.loading = true;

				that.greq('post', '/api/exchange_result2', {
					from:that.from_coin,
					amount: that.type ? that.to_amount : that.from_amount, 
					to:that.to_coin,
					do:1,
					type:that.type
				}, function(data){
					that.$parent.loading = false;
					if( data.status ){
						// show the result...
						that.show_result_dialog = true;
						that.get_balance();
					} else {
						that.$message({message:data.msg, type:'error'})
					}
				})
			},
			get_balance: function(){
				let that = this;
				that.greq('post', '/api/user_balance', {}, function(data){
					if( data.status ){
						that.balance_list = data.data;
					}
				})
			}
		},
		mounted() {
			this.from_coin = this.coins[0].value;
			this.from_img = this.coins[0].img;

			this.to_coin = this.coins[1].value;
			this.to_img = this.coins[1].img;

			// get balance list
			this.get_balance();
		}
	}
</script>

<style>
	.exchange_container .stf-select_sm {
		height: 50px;
		line-height: 50px;
	}

	.exchange_container .stf-select__inner-wrapper {
		background-color: #fff;
	}

	.stf-select__options {
		border: 1px solid #eee;
	}

	.exchange_pages .el-input__inner {
		border: none;
	}

	.exchange_pages .el-select-dropdown__item{
		height:auto;
	}

	.exchange_pages .el-input--prefix .el-input__inner{
		text-indent: 5px !important;
	}
</style>